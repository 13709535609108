import React from "react";
// Customizable Area Start
import EmailAccountRegisterationController, { Props,CountryDatatype } from "./EmailAccountRegistrationController.web";
import {
  Grid, Box, Typography, TextField, styled, InputAdornment, IconButton, 
} from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {  logo } from "./assets";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PhoneInput from "react-phone-input-2";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorSuccessPopUp from "../../../components/src/ErrorSuccessPopUp.web";
// Customizable Area End


export default class EmailAccountRegisteration extends EmailAccountRegisterationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFirstForm = () => {
    const validationSchema = Yup.object().shape({
      username: Yup.string()
        .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed for this field'),
        
      last_name: Yup.string()
        .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed for this field')
        
    });
    return (
      <Formik
        data-test-id="FirstformData"
        validationSchema={validationSchema}
        initialValues={{ username: "", last_name: "" }}
        onSubmit={(values, { setStatus }) => {
          this.handleFirstForm(
            this.handleValidCheck(values.username, values.last_name),
            setStatus,
            () => this.handleSetStatus(setStatus, values.username, values.last_name)
          );
        }}
      >
        {({ status, values, handleSubmit,handleChange, errors,touched }) => (
          <Form noValidate className="form" 
          onSubmit={handleSubmit}>
            <Box mb={2}>
              {status && (!values.username || !values.last_name) && (
                <Box className="statusError" p={2} borderRadius={4} boxShadow={2} textAlign="center">
                  {status}
                </Box>
              )}
            </Box>
            <Box className="formFieldParent">
              <Box className="firstNameField">
                <Typography variant="subtitle1" className="firstNamePlaceHolder">
                  First Name
                </Typography>
                <Field
                  inputProps={{
                    "data-test-id": "UserName"
                  }}
                  as={TextField}
                  name="username"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  placeholder="Your first name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    if (/^[A-Za-z]*$/.test(value)) {
                      handleChange(event);
                    }
                  }}
                  helperText={touched.username && errors.username}
                  error={touched.username && Boolean(errors.username)}
                />
              </Box>
              <Box>
                <Typography variant="subtitle1" className="lastNamePlaceHolder">
                  Last Name
                </Typography>
                <Field
                  as={TextField}
                  name="last_name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  placeholder="Your last name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    if (/^[A-Za-z]*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  helperText={touched.last_name && errors.last_name}
                  error={touched.last_name && Boolean(errors.last_name)}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={6}>
              <Typography onClick={this.handleNavigationSignIn} data-test-id="back0" className="mainPageText">
                Back to main page
              </Typography>
              <button data-test-id="next0" type="submit" className="nextBtn" >
                Next
              </button>
            </Box>
          </Form>
        )}
      </Formik>
    );
  };
  handleGetCountryData = (item:CountryDatatype, index:number)=>{                    
    return(
        <option data-test-id={`country-${index}`} key={index} style={{color:'#000'}} value={item?.attributes?.name} >{item?.attributes?.name}</option>
    )
  } 

  handleFirmError= (a:string,c:null)=>{
    return a ? <Box data-test-id="boxID" style={{color:"red",fontFamily:'DM Sans',fontWeight:400}}>{this.state.firmNameError}</Box> : c
  }
  handlePhoneError= (a:string,c:null)=>{
    return a ? <Box style={{color:"red",marginTop:"5px",fontFamily:'DM Sans',fontWeight:400}} >{this.state.phoneNumberError}</Box> : c
  }

  renderSecondForm = () => {
    return (  <Formik data-test-id="formik2"
      initialValues={{ firmName: this.state.lawFirmName || "", country: 'none', contactNumber: "" }}
      onSubmit={(values, { setStatus }) => {

        if (this.state.lawFirmName ? '' : !values.firmName || !values.country || !values.contactNumber) {
          return (
            setStatus('Fill all mandatory fields to continue')
          )
        }
        else{
          setStatus(null);
          this.setState({ firmName: values.firmName, country: values.country, contactNumber: values.contactNumber })
          this.validateSignUp({ firmName: values.firmName, contactNumber: values.contactNumber });
        }
        
      }}
      >
        {({ status,values, setFieldValue, handleSubmit,handleChange, errors,touched,setFieldTouched }) => (
          <Form onSubmit={handleSubmit}   className="form">
            <Box mb={2}>
              {status && (!values.firmName || !values.country || !values.contactNumber) && (
                <Box className="statusError" p={2} borderRadius={4} boxShadow={2} textAlign="center">
                  {status}
                </Box>
              )}
            </Box>
            <Box >
              <Box className="firstNameField">
                <Typography variant="subtitle1" className="firstNamePlaceHolder">
                  Company/Law firm
                </Typography>
                <TextField
                  name="firmName"
                  fullWidth
                  data-test-id="firmnameid"
                  value={this.state.lawFirmName||values.firmName}
                  margin="normal"
                variant="outlined"
                disabled={this.state.lawFirmName ? true : false}
                  placeholder="Your company/law firm"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    if (/^[A-Za-z0-9\s!"#$%&'()*+,\-.\/:;<=>?@[\\\]^_`{|}~]*$/.test(value)) {
                      handleChange(event);
                    }
                    this.setState({firmNameError:""})
                  }}
                  helperText={touched.firmName && errors.firmName}
                  error={touched.firmName && Boolean(errors.firmName)}
                  InputProps={{
                    style: this.state.firmNameError ? { borderColor: 'red' } : {}
                  }}
                />
                {this.handleFirmError(this.state.firmNameError,null)}
              </Box>              
              <Box className="firstNameField">
                <Typography variant="subtitle1" className="firstNamePlaceHolder">
                Jurisdiction
                </Typography>
                <select 
                  className="selectCountry"
                  data-test-id="country"
                  name="country"
                  style={{ color: values.country == undefined ? '#BFBFBF' : "#000" }}
                  value={values.country ?? ""}
                  onChange={(event) => setFieldValue("country", event.target.value)}
                >

                  <option disabled value="none" >Select Country</option>
                  {this.state.countryData?.map(this.handleGetCountryData)}                 
                </select>
              </Box>
              <Box className="firstNameField">
                <Typography variant="subtitle1" className="firstNamePlaceHolder">
                  Phone Number
                </Typography>
                <PhoneInputStyle className="phoneInput">
                  <PhoneInput
                    onBlur={() => setFieldTouched("contactNumber",true)}
                    country={this.state.countryCode||'in'}
                    placeholder={""}                    
                    inputProps={{
                      required: true,
                      id: "contactNumber",
                      name: "contactNumber",
                      "data-test-id": "PhoneNumberModel",                      
                    }}
                    enableSearch={true}
                    disableSearchIcon={true}
                    jumpCursorToEnd={true}
                    countryCodeEditable={false}
                    inputClass={"PhoneInputPackgeClass"}
                    enableAreaCodes={true}
                    value={values.contactNumber}
                    onChange={(event, index: { dialCode: string ,countryCode:string }) => { setFieldValue("contactNumber", `+${event}`); this.handleChange(event, index.dialCode);this.setState({phoneNumberError:""}) }}
                  />
                </PhoneInputStyle>
                {this.handlePhoneError(this.state.phoneNumberError,null)}
              </Box>
              <Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={6}>
              <Typography data-test-id="back1" onClick={this.handleBack} className="mainPageText">
                Back to previous page
              </Typography>
              <button type="submit" data-test-id="next1" className="nextBtn" >
                Next
              </button>
            </Box>
          </Form>
        )}
      </Formik>
    )

  }
  renderErrorBox = (formikError: string | undefined, stateError: string | undefined) => {
    const errorMessage = 
    formikError || stateError;
    if (!errorMessage) 
      {
      return null
    }
    return (
      <Box className="statusError" p={2} borderRadius={4} boxShadow={2} textAlign="center">
        {errorMessage}
      </Box>
    );
  };

  hanldeEmailError = (errors: any) => {
    return (
      <span className="statusErrorCustom">
        {this.state.emailError || errors.email && <div className="statusErrorCustom" data-test-id="errorBox" style={{ color: 'red' }}>
          {this.state.emailError || errors.email}
        </div>}
        {!this.state.emailError && !errors.email && this.state.errorVerifiedEmail && this.state.errorCheck && <div className="statusErrorCustom" style={{ color: 'red' }}>
        Email provided does not match domain record
        </div>}
      </span>
    );
  }

  renderThirdForm = () => {
    const validationSchema = 
    Yup.object().shape({
      email: Yup.string().email('Invalid email address').required('Email field cannot be empty'),
      password: Yup.string()
        .min(8, "Minimum 8 characters")
        .matches(/[A-Z]/, 'Password does not match requirements')
        .matches(/[a-z]/, 'Password does not match requirements')
        .matches(/\d/, 'Password does not match requirements').required('Password is required')
    });


    return ( 
    <Formik
        data-test-id="formik3"
        initialValues={this.state.form3Values}
        validationSchema={validationSchema}
        onSubmit={async(values, { setStatus, setSubmitting }) => {
          setStatus(null); 
          try {
            await validationSchema.validate(values, { abortEarly: false });
            const formData = {
              username: this.state.username,
              last_name: this.state.last_name,
              firmName: this.state.firmName,
              contactNumber: this.state.contactNumber,
              country: this.state.country,
              email: values.email,
              password: values.password,
            };
            this.handleFormThirdCheck(!this.state.errorVerifiedEmail,this.handleThirdValues(formData,setSubmitting))
            
            
          } catch (error) {
            const typedError = error as Yup.ValidationError;
            const errorMessage = typedError.errors ? typedError.errors[0] : 'Validation failed';
            setStatus(errorMessage);
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors,  handleSubmit, handleChange }) => {
          return ( 
          <form className="thirdForm" 
          onSubmit={handleSubmit}>
              <Box mb={2}>
                {this.renderErrorBox(errors.email, this.state.emailError)}
              </Box>
              <Box className="firstNameField">
                <Typography variant="subtitle1" className="firstNamePlaceHolder">
                  Email address
                </Typography>
                <TextField
                  data-test-id="emailThree"
                  name="email"
                  type="email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  placeholder="Your email"
                  value={values.email} 
                  onChange={(event) => {
                    handleChange(event);
                    this.handleEmailChangeFunction(event,handleChange)
                  }}  
                  className={this.handleEmailErrorClass(errors.email,this.state.errorVerifiedEmail,this.state.errorCheck,this.state.emailError)}                
                />
                {this.hanldeEmailError(errors)}
                
                <Box>
                  <Typography variant="subtitle1" className="lastNamePlaceHolder">
                    Password
                  </Typography>
                  <Field
                    as={TextField}
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    fullWidth
                    data-test-id="pass3"
                    margin="normal"
                    variant="outlined"
                    placeholder="Your password"
                    className={errors.password && this.state.error_shown ? "errorOutline" : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      if (/^[A-Za-z0-9!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/~` ]*$/.test(value)) {
                        handleChange(event);
                        this.setState({error_shown:true})
                      }
                    }}

                    onFocus={this.handleFocusError
                  }
                    onBlur={() => !values.password && this.setState({ showPasswordCriteria: false })}
                    InputProps={{
                      "data-test-id": "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                          data-test-id="toggleNewPasswordVisibility"
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                {this.handleErrorBox(this.state.error_shown,<div className="statusErrorCustom" data-test-id="passwordError" style={{ color: 'red' }}>
                  {errors.password}
                </div>,"")}
                {this.handlePasswordCriteria(values.password)}
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center" mt={6}>
                <Typography data-test-id="back2" onClick={this.handleBack} className="mainPageText">
                  Back to previous page
                </Typography>
                <button
                  type="submit"
                  className="nextBtn"
                  data-test-id="next2"               
                  onClick={()=>this.handleErrorChange(errors)}
                >
                  {this.handleSubmitVerify(values.email,values.password,!errors.email,!errors.password)}
                </button>
                {this.state.showPop && <ErrorSuccessPopUp
                  showPop={this.state.showPop}
                  setShowPop={(newState: boolean) => this.setState({ showPop: newState })}
                  showResponse={this.state.ShowResponse}
                  termfunction={this.handleNavigationTerms}
                  deletePop={false}
                  loginNav={() => {}}
                />}
              </Box>
            </form>
          )
        }}
      </Formik>
    );
  }

  handlePasswordCriteria=(values:string)=>{
    return(
      <span>
          {this.state.showPasswordCriteria && (
                  <Box mt={2}>
                    <Typography className="errorParent">Your password must contain:</Typography>
                    <Box display="flex" alignItems="center">
                      <IconButton>
                        {/[A-Z]/.test(values) ? (
                          <CheckCircleIcon style={{ color: 'green' }} />
                        ) : (
                          <RemoveCircleIcon style={{ color: 'red' }} />
                        )}
                      </IconButton>
                      <Typography className="passErrorText">At least one uppercase letter</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className="errorBox">
                      <IconButton>
                        {values && /[a-z]/.test(values) ? (
                          <CheckCircleIcon style={{ color: 'green' }} />
                        ) : (
                          <RemoveCircleIcon style={{ color: 'red' }} />
                        )}
                      </IconButton>
                      <Typography className="passErrorText">At least one lowercase letter</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className="errorBox">
                      <IconButton>
                        {/\d/.test(values) ? (
                          <CheckCircleIcon style={{ color: 'green' }} />
                        ) : (
                          <RemoveCircleIcon style={{ color: 'red' }} />
                        )}
                      </IconButton>
                      <Typography className="passErrorText">At least one number</Typography>
                    </Box>
                  </Box>
                )}
      </span>
    )
  }

  renderForm = () => {
    const { activeStep } =
     this.state;
    switch (activeStep) 
    {
      case 0:
        return this.renderFirstForm();
      case 1:
        return this.renderSecondForm();
      case 2:
        return this.renderThirdForm();
      default:
        return null;
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start 
    const totalSteps = 
    this.state.steps.length;
    const initialProgress = 10;
    const stepPercentage = 
    ((this.state.activeStep) / (totalSteps - 1)) * (100 - initialProgress) + initialProgress;
    return (
      <MainGrid container data-test-id="signupPage" >
        <Grid item xs={12} className="logoGrid">
          <img src={logo} alt="Logo" />
        </Grid>
        <Grid container item xs={12} justifyContent="center" alignItems="center" className="secondGridItem">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={3}
            className="formBox"
          >
            <Box
              className="stepperParent"
            >
              <Box
                style={{
                  width: `${stepPercentage}%`,

                }}
                className="stepper"
              />
            </Box>
            <Typography variant="h4" gutterBottom className="signupText">
              Sign up to Digip
            </Typography>
            {this.renderForm()}
          </Box>
        </Grid>
      </MainGrid>
    );

    // Customizable Area End
  }
}

// Customizable Area Start
const PhoneInputStyle = styled(Box)({
  "& .country-list": {
    top: "0px !important",
    height: "155px !important",
  },
  "& .react-tel-input .country-list .country-name": {
    fontFamily: "DM Sans",
  },
  "& .react-tel-input": {
    fontFamily: "DM Sans",
  },
  "& .PhoneInputPackgeClass": {
    width: "100%",
    height: "56px",
    borderRadius: "5px",
    fontSize: "16px",
    boder: "none !important",
    backgroundColor: "white",
  },
  "& .flag-dropdown": {
    boder: "none !important",
    borderTopLeftRadius:"6px",
    borderBottomLeftRadius:"6px",
    "&:focus":{
      border:'2px solid #3f51b5;'
    }
  },
  "& .PhoneInput": {
    position: "relative",
    "& .PhoneError": {
      fontSize: "18px",
      color: "red",
      margin: "6px 14px",
      position: "absolute",
    },
    
  },
  '&:hover': {
    border:'1px solid #000',
    opacity:.8,
    borderRadius:"4px"
  },
  "&:focus-within": {
    border: '2px solid #3f51b5',
    borderRadius: "6px",
  }


});
const MainGrid = styled(Grid)({
  height: '90vh', position: 'relative',
  "& .logoGrid": {
    width: "100%",
    borderRadius: "45px",
    padding: "13px",
    color: "#ffff"
  },
  "& .logo": {
    width: '100px', height: 'auto'

  },
  "& .secondGridItem": {
    height: '100%'
  },
  "& .formBox": {
    width: '100%', maxWidth: '400px'
  },
  "& .stepperParent": {
    width: '100%',
    height: '9px',
    backgroundColor: '#ccc',
    position: 'relative',
    marginBottom: '50px',
    borderRadius: "15px"

  },
  "& .stepper": {
    height: '100%',
    backgroundColor: '#355EE7',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: "15px"

  },
  "& .signupText": {
    width: '100%', textAlign: 'left', marginBottom: "30px", fontFamily: "DM Sans",
    fontSize: "33px", fontWeight: 700
  },
  "& .form": {
    width: '100%'
  },
  "& .statusError": {
    borderLeft: "4px solid #F87171", fontFamily: "DM Sans", fontWeight: "400", fontSize: "12px", textAlign: "left",
    backgroundColor: "#FEE2E2", color: "#DC2626", boxShadow: "none"
  },
  "& .statusErrorCustom": {

    fontFamily: "DM Sans", fontWeight: "400", fontSize: "12px", textAlign: "left",
    color: "red", boxShadow: "none", marginBottom: "20px"

  },
  "& .formFieldParent": {
    padding: "0px 40px"
  },
  "& .firstNameField": {
    marginBottom: "20px"
  },
  "& .phoneInput": {
    marginTop: "13px"
  },
  "& .firstNamePlaceHolder": {
    textAlign: 'left', marginBottom: "-10px", fontFamily: "DM Sans", fontWeight: "400", fontSize: "16px", color: "#434343"
  },
  "& .lastNamePlaceHolder": {
    textAlign: 'left', marginBottom: "-10px", fontFamily: "DM Sans", fontWeight: "400", fontSize: "16px", color: "#434343"
  },
  "& .mainPageText": {
    color: "#355EE7", fontFamily: "DM Sans", fontWeight: 400, fontSize: "16px",cursor:"pointer"
  },
  "& .nextBtn": {
    border:'none',textTransform:'none',fontFamily:"DM Sans", fontWeight: 400, fontSize: "16px",cursor:'pointer',
    borderRadius: "8px", padding: "10px 16px 10px 16px", width: "120px", height: "56px",backgroundColor:"#355EE7",color:"#fff",
    '&:hover': {
      border:'3px solid #B9C5FF',
      boxShadow:'none !important'
    }
  },
  "& .errorBox": {
    marginTop: "-10px"
  },
  "& .errorParent": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    marginTop: "20px"
  },
  "& .errorOutline": {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
    },
  },
  "& .thirdForm": {
    width: "100%"
  },
  "& .passErrorText": {
    marginTop: "-2px !important",
    fontFamily:"DM Sans",
  },
  "& .selectCountry": {
    width: '100%',
    padding: '15px',
    border: '0.4px solid #c5cbc6',  
    borderRadius: '4px',
    fontSize: '16px',
    marginTop: '16px',
    marginBottom: '8px',
    outline: 'none',
    backgroundColor: '#fff',
    backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'%23c5cbc6\' height=\'20\' viewBox=\'0 0 24 24\' width=\'20\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/></svg>")',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '95%',
    backgroundPositionY: 'center',
    appearance: 'none',
    fontWeight:400,
    fontFamily:"DM Sans",
    '&:hover': {
      border:'1px solid #000',
      borderRadius:"8px"
    },
    "&:focus":{
      border:'2px solid #3f51b5;',
      borderRadius:"8px"
    }
    
  },
  "& .contactError":{
    color:"red",
    fontSize:"18px",
    fontFamily:"DM Sans",
    fontWeight:400
  }
})

// Customizable Area End