import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import {  removeStorageData, setStorageData } from "../../../framework/src/Utilities";


export interface CountryDatatype {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  steps: string[];
  contactNumber:string,
  showPassword:boolean,
  showPasswordCriteria:boolean,
  countryCode:string,
  countryCodeMisMatch:boolean,
  form3Values: {
    email: string;
    password: string;
  };
  errors: {
    email: string;
    password: string;
  };
  status: string | null;
  username:string,last_name:string,country:string,firmName:string,
  firmNameError:string,
  phoneNumberError:string,
  emailError:string,
  lawFirmName:string,
  countryData:CountryDatatype[],
  roleData:string;
  countryName: string;
  is_Shared:string | null;
  Verified_Email:string;
  error_shown:boolean;
  errorVerifiedEmail:boolean;
  errorCheck:boolean,
  lawyerToken:string|null,
  showPop:boolean
  ShowResponse:{
    error:string,
    success:string
  }

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EmailAccountRegisterationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  UserSignUpApiCallId: string = "";
  UserSignupValidateCallId: string = ""
  getCountryApiCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];


    this.state = {
      activeStep: 0,
    steps: ['Account Details', 'Personal Information', 'Confirmation'],
    contactNumber:"",
    showPassword: false ,
    showPasswordCriteria: false,
    
    form3Values: {
      email: '',
      password: ''
    },
    countryName: "",
    countryCode:"",
    countryCodeMisMatch:false,
    errors: {
      email: '',
      password: ''
    },
    status: null,
    username:"",last_name:"",country:"",firmName:"",
    firmNameError:"",
    phoneNumberError:"",
    emailError:"",
    lawFirmName:'',
    countryData:[],
    roleData:'0',
    is_Shared:"",
    error_shown:false,
    Verified_Email:"",
    errorVerifiedEmail:false,
    errorCheck:false,
    lawyerToken:"",  
    showPop:false,
    ShowResponse:{
      error:"",
      success:""
    }
  };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.UserSignUpApiCallId) {
      this.userAsyncFunction(responseJson)
      
    }
    if (apiRequestCallId === this.UserSignupValidateCallId) {
      if (responseJson.errors) {
        const errors: string[] = responseJson.errors; 
        let firmNameError = '';
        let phoneNumberError = '';
      
        errors.forEach((error: string) => {
          switch (error) {
            case 'Lawfirm has already been created':
              firmNameError = 'This Lawfirm has already been created';
              break;
            case 'Invalid or Unrecognized Phone Number':
              phoneNumberError = 'Invalid or Unrecognized Phone Number';
              break;
            case 'Phone number has already been taken':
              phoneNumberError = 'Phone number has already been taken';
              break;
          }
        });
      
        return this.setState({
          firmNameError,
          phoneNumberError
        });
      } else {
        this.handleNext();
      }
      
    }
    if (apiRequestCallId === this.getCountryApiCallId) {
      this.setState({ countryData: responseJson?.data })
    }
    
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(){
    super.componentDidMount();
    const urlParams = new URLSearchParams(window.location.search);
    const firmName = urlParams.get('firm_name');
    const Email = urlParams.get('recipient_email');
    const role = urlParams.get('role');
    const token1 = urlParams.get('token')
    this.setState({roleData:JSON.stringify(urlParams.get('role')),Verified_Email:JSON.stringify(Email)})    
    if (role) {
      localStorage.setItem('roleData', JSON.stringify(role));
    }
    if(urlParams.get('role')!=""){
      this.setState({ is_Shared: "true",errorCheck:true})
    }
    if(firmName!= null||""){
      this.setState({ lawFirmName: `${firmName}`,lawyerToken:token1})
    }
        
    this.getCountryList()
  }

  userAsyncFunction= (responseJson:any)=>{
    console.log("this",this.state.errorVerifiedEmail)
     if ( responseJson?.errors) {
      if(responseJson.errors[0]?.token||responseJson.errors[0]?.pin||responseJson.errors[0]?.message){
        this.setState({showPop:true,ShowResponse:{error:responseJson.errors[0].token||responseJson.errors[0].pin||responseJson.errors[0].message,success:""}})
      }
      else if (typeof responseJson.errors === "string") {
        this.setState({ emailError: responseJson.errors });
      }
    } 
   else{
      this.setState({showPop:true,
        ShowResponse:{error:"",success:responseJson?.meta?.message}
      })
      setStorageData("userdetails",JSON.stringify(responseJson));
      setStorageData("is_shared",JSON.stringify(this.state.is_Shared));
      setStorageData("roleName",this.state.roleData);
      removeStorageData("token");}
  }
  
  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };
  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  }

  handleErrorBox=(a:boolean,b:any,c:string)=>{
    return a?b:c
  }
  handleErrorChange=(errors:{ password?: string; email?: string })=>{
    if(errors.password){
    this.setState({error_shown:true})
    }
    else{
      this.setState({error_shown:false})
    }
  }
  handleFocusError=()=>{
    this.setState({ showPasswordCriteria: true , error_shown:false })
  }
  handleSubmitVerify=(a:string,b:string,c:string|undefined|boolean,d:string|boolean)=>{
     return a&&b&&c&&d?"Submit":"Next"
  }

  handleFirstForm = (
    isValid: boolean,
    setStatus: (status?: string | null) => void,
    onSuccess: () => void
  ) => {
    if (!isValid) {
      if (typeof setStatus === 'function') {
        setStatus('Fill all mandatory fields to continue');
      }
    } else {
      if (typeof setStatus === 'function') {
        setStatus();
      }
      onSuccess();
    }
  };
  
  handleValidCheck = (username:any, last_name:any) => {
    return !!(username && last_name);
  };
  
  handleSetStatus = (setStatus:any, username:any, last_name:any) => {
    this.setState({ username, last_name });
    this.handleNext();
  };

  handleEmailChangeFunction = (event: any, handleChange: any) => {
    this.setState({ emailError: "" });
    this.handleVerifiedCheck(event)
  }
  handleVerifiedCheck = (event: any) => {
    if (this.state.errorCheck) {
      if (this.state.Verified_Email !== "" && this.state.Verified_Email !== "null") {
        this.setState({
          errorVerifiedEmail: JSON.stringify(event.target.value) === this.state.Verified_Email ? false : true
        });
      }
    }
  }

  handleEmailErrorClass=(a:string|undefined,b:string|boolean,c:string|boolean,d:string|boolean)=>{
    return a||this.handleConditionCheck(b,c)||d ? "errorOutline":""
  }
  handleConditionCheck=(a:string|boolean,b:string|boolean)=>{
    return a&&b
  }



  handleThirdValues=(formData:{
    username: string,
    last_name: string,
    firmName: string,
    contactNumber: string,
    country: string,
    email: string,
    password: string}
    ,setSubmitting: (isSubmitting: boolean) => void) =>{
    if(this.state.errorVerifiedEmail===false){
      this.signUpUser(formData);
      setSubmitting(true);
    }
  }
  handleFormThirdCheck=(a:boolean,b:any)=>{
    return a?b:""
  }

  handleChange = (number: string, numberDetails: string) => {
    const phoneNumber = number.slice(numberDetails.length, number.length);
    const countryCode = number.slice(0, numberDetails.length)
    this.setState({
      contactNumber: `+${countryCode} ${phoneNumber}`
    });
    
  };
  
  handleNavigationSignIn=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleNavigationTerms=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TermsConditions");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword
    }));
  };

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  functionEndPoint = () => {
    const { lawyerToken } = this.state;
    return lawyerToken && lawyerToken !== ""
      ? `account_block/accounts?token=${lawyerToken}`
      : "account_block/accounts";
  }

  signUpUser =  (values:{email:string,password:string,username:string,last_name:string,contactNumber:string,country:string,firmName:string}) => {
    const header = {
      "Content-Type": "application/json",
    };
    
    const httpBody = {
      "data": {
        "type": "email_account",
        "attributes": {
          "first_name": values.username,
          "last_name": values.last_name,
          "full_phone_number": values.contactNumber.replace("+", ''),
          "jurisdiction": values.country,
          "firm": this.state.lawFirmName ? this.state.lawFirmName : values.firmName,
          "email": values.email,
          "password": values.password,
          "user_role": (this.state.roleData === `"lawyer"`||this.state.roleData === `"Lawyer"`) ? 1 : 0,
          "is_shared": (this.state.is_Shared == "true") ? "true" : ""
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UserSignUpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.functionEndPoint()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  validateSignUp = (values:{firmName:string,contactNumber:string}) => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    

    this.UserSignupValidateCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/validate_signup?firm=${values.firmName}&full_phone_number=${values.contactNumber.substring(1)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }  

  getCountryList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_countries"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
